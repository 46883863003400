@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Afacad+Flux:wght@100..1000&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.custom-html-description a {
  @apply text-blue-500 hover:underline
}

.custom-html h1 {
  @apply text-4xl font-bold;
}

.custom-html h2 {
  @apply text-3xl font-semibold;
}

.custom-html h3 {
  @apply text-2xl font-medium;
}

.custom-html p {
  @apply text-sm leading-relaxed;
}

.custom-html ul {
  @apply list-disc;
}

.custom-html ol {
  @apply list-decimal;
}


.custom-html blockquote {
  @apply border-l-4 border-gray-300 pl-4 italic text-gray-600;
}

.custom-html a {
  @apply text-blue-500 underline;
}

.custom-html strong {
  @apply font-bold;
}

.custom-html em {
  @apply italic;
}

.custom-html code {
  @apply bg-gray-100 text-red-500 px-1 rounded;
}

.custom-html pre {
  @apply bg-gray-100 p-4 rounded-md overflow-x-auto;
}

