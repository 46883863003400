* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Custom Swiper navigation button styles */
.swiper-button-next,
.swiper-button-prev {
  color: #ffffff;
  width: 30px; /* Adjust as needed */
  height: 30px; /* Adjust as needed */
  background-color: orangered; /* Optional: Background to make buttons stand out */
  border-radius: 50%; /* Optional: Circular buttons */
  display: flex;
  transform: translateY(-70px);
  align-items: center;
  justify-content: center;
  z-index: 10; /* Ensure buttons are above slides */
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 20px; /* Adjust size */
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: 0;
}

/* FAQ.css */
.fade-enter {
  opacity: 0;
  max-height: 0;
}
.fade-enter-active {
  opacity: 1;
  max-height: 200px; /* Adjust based on content size */
  transition: opacity 300ms ease-in-out, max-height 300ms ease-in-out;
}
.fade-exit {
  opacity: 1;
  max-height: 200px; /* Adjust based on content size */
}
.fade-exit-active {
  opacity: 0;
  max-height: 0;
  transition: opacity 300ms ease-in-out, max-height 300ms ease-in-out;
}

/* A4 size in pixels */
.A4-size {
  max-width: 210mm; /* A4 width in mm */
  min-height: 297mm; /* A4 height in mm */
  background-color: white;
  margin: 0 auto; /* Center the preview */
  box-sizing: border-box;
  border: 1px solid black;
}

/* Print styles */
@media print {
  body {
    margin: 0;
    padding: 0;
  }
  .A4-size {
    width: 210mm; /* A4 width in mm */
    height: 297mm; /* A4 height in mm */
    margin: 0; /* No margin */
    box-sizing: border-box;
    page-break-after: always;
    border: none;
  }
}

@media print {
  * {
    -webkit-print-color-adjust: exact !important; /* Ensures background graphics are printed */
    print-color-adjust: exact !important; /* Standard property */
  }
}
